import React, { useEffect, useState } from "react";
import "./PostedJobs.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Briefcase from "../../Assests/posted-jobs/Briefcase.svg";
import Location from "../../Assests/posted-jobs/location.svg";
import Pen from "../../Assests/posted-jobs/Pen.svg";
import Footer from "../../Components/Footer/Footer";
import Nav2 from "../../Components/Nav2/Nav2";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { getAllJobs } from "../../actions/alljobsAction";
import { getDeleteJob } from "../../actions/deleteJobAction";
import Modal from "react-modal";
import FullPageLoader from "../../Components/fullpageloader/fullPageLoader";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

// method to post jobs
function PostedJobs(props) {
  const { page, limit } = useParams();
  const [limitNumber, setLimitNumber] = useState(limit);
  const [pageNumber, setPageNumber] = useState(parseInt(page));
  const [pageNumbers, setPageNumbers] = useState([]);

  const handleChange = (event) => {
    window.location = `page=1&limit=${event.target.value}`;
  };

  console.log(limit);

  useEffect(() => {
    setPageNumbers(
      Array.from(
        { length: props?.alljobsReducer?.alljobs?.total_pages },
        (_, index) => index + 1
      )
    );
  }, [props?.alljobsReducer?.alljobs?.total_pages]);

  const [registerModalIsOpen, setRegisterModalIsOpen] = useState(false);
  const [j_id, setJ_id] = useState(null);
  useEffect(() => {
    jobData(
      localStorage.getItem("auth_id1"),
      pageNumber,

      limit
    );
  }, []);

  const jobData = async (userId, pageNumber, limit) => {
    await props.getAllJobs(userId, pageNumber, limit);
    return null;
  };
  const deletefunc = (e) => {
    setRegisterModalIsOpen(true);
    setJ_id(e);
    // console.log("dd",j_id)
  };

  const handlePreviousPage = () => {
    const previousPage = pageNumber - 1;
    if (pageNumber > 1) {
      window.location = `page=${previousPage}&limit=${limit}`;
    }
  };
  const handleNextPage = () => {
    const nextPage = pageNumber + 1;
    if (props?.alljobsReducer?.alljobs.total_pages > pageNumber) {
      window.location = `page=${nextPage}&limit=${limit}`;
    }
  };

  const handleNavClick = (currentPage) => {
    window.location = `page=${currentPage}&limit=${limit}`;
  };

  const handlePaginationClick = (event, page) => {
    window.location = `page=${page}&limit=${limit}`;
  };
  return (
    <>
      <Modal
        isOpen={registerModalIsOpen}
        onRequestClose={() => setRegisterModalIsOpen(false)}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.4)",
          },
          content: {
            position: "absolute",
            margin: "0 auto",
            width: "450px",
            height: "200px",
            top: "50%",
            left: "0",
            right: "0",
            bottom: "40px",
            border: "1px solid #ccc",
            background: "#fff",
            WebkitOverflowScrolling: "touch",
            borderRadius: "4px",
            outline: "none",
            padding: "20px",
            boxShadow: "0 0 5px 5px #f2f2f2",
            borderRadius: "20px",
            background: "#fff",
            border: "1px solid #fff",
          },
        }}
      >
        {/* Heading Starts */}
        <div className="row">
          <div className="col-md-12">
            <h2 className="text-danger text-center pt-2">Delete Job</h2>
          </div>
          <div className="col-md-12">
            <p className=" text-center pt-2">
              Are you sure you want to delete this job?
            </p>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-4"></div>
              <div className="col-md-2">
                <a
                  href="#"
                  onClick={() =>
                    props.getDeleteJob(localStorage.getItem("auth_id1"), j_id)
                  }
                >
                  <h5 className="text-danger text-center">Yes</h5>
                </a>
              </div>
              <div className="col-md-2">
                <a
                  href="#"
                  className="text-dark"
                  onClick={() => setRegisterModalIsOpen(false)}
                >
                  {" "}
                  <h5 className="text-center">No</h5>
                </a>
              </div>
              <div className="col-md-4"></div>
            </div>
          </div>
        </div>
        {/* Heading Starts */}
      </Modal>

      <Nav2 />
      <div className="container container-bottom padder">
        <div className="row mb-3">
          <div className="col-md-12">
            <h1 className="posted-jobs-rexr-head">Posted Jobs</h1>
            {props?.alljobsReducer?.alljobs?.jobs && (
              <h6 className="posted-jobs-rexr-para">
                Showing{" "}
                {props?.alljobsReducer?.alljobs?.jobs?.length < limitNumber ? (
                  <>
                    {" "}
                    {(pageNumber - 1) * limitNumber} -{" "}
                    {props.alljobsReducer.alljobs.total_records}
                  </>
                ) : (
                  <>
                    {pageNumber == 1
                      ? "1"
                      : props?.alljobsReducer?.alljobs?.jobs?.length *
                        (pageNumber - 1)}{" "}
                    -{" "}
                    {props?.alljobsReducer?.alljobs?.jobs?.length *
                      (pageNumber - 1) +
                      parseInt(limitNumber)}
                  </>
                )}{" "}
                Posted Jobs out of {props.alljobsReducer.alljobs.total_records}{" "}
                jobs!
              </h6>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-md-9">
            {/* Apis Start here */}

            <div className="row">
              <div className="col-lg-4 col-md-6">
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Limit Jobs By Number{" "}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={limitNumber}
                      label="Limit Jobs By Number"
                      onChange={handleChange}
                    >
                      <MenuItem value={25}>25</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                      <MenuItem value={75}>75</MenuItem>
                      <MenuItem value={100}>100</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </div>
            </div>

            <br />
            {props.alljobsReducer.alljobs.jobs &&
            props.alljobsReducer.alljobs.jobs.length > 0 ? (
              props.alljobsReducer.alljobs.jobs.map((alljobs) => (
                <div className="job_result" key={alljobs.id}>
                  <div className="col-md-12 text-right">
                    <div
                      onClick={(e) => deletefunc(alljobs.id)}
                      style={{ textDecoration: "none" }}
                      className="delete-icon-hover"
                    >
                      {" "}
                      <i
                        className="fa-solid fa-trash"
                        style={{
                          color: "#f50000",
                          fontSize: "19px",
                          cursor: "pointer",
                        }}
                      ></i>
                    </div>
                  </div>
                  <Link
                    to={`/PostedJobsDesc?id=${alljobs.id}`}
                    className="link-tag-home"
                  >
                    <div className="row">
                      <div className="col-md-6">
                        <h4 className="text-color m-0 p-0">
                          {alljobs.job_title !== null &&
                          alljobs.job_title !== undefined
                            ? alljobs.job_title
                            : ""}
                        </h4>
                      </div>
                      <div className="col-md-6">
                        <p
                          className="closed-color-of-jobs"
                          style={{ color: "green" }}
                        >
                          {alljobs.status !== null &&
                          alljobs.status !== undefined
                            ? alljobs.status
                            : ""}
                        </p>
                      </div>
                    </div>
                    <p className="text-disabled pt-1">
                      {alljobs.rec !== null && alljobs.rec !== undefined
                        ? alljobs.rec
                        : ""}
                    </p>
                    <div className="info-tags">
                      <span className="ml-0 text-disabled-02">
                        <img src={Briefcase} alt="" width="16px" />
                        {`  `}{" "}
                        {alljobs.exp !== null && alljobs.exp !== undefined
                          ? alljobs.exp
                          : ""}
                      </span>
                      <span className="text-disabled-02">
                        <img src={Location} alt="" width="10px" />

                        {/* {alljobs.city!==null&&alljobs.city!==undefined?alljobs.city:""}  */}
                        {`  `}
                        {alljobs.country !== null &&
                        alljobs.country !== undefined
                          ? alljobs.country
                          : ""}
                        {`  `}
                        {alljobs.state !== null && alljobs.state !== undefined
                          ? alljobs.state
                          : ""}
                        {`  `}
                        {alljobs.city !== null && alljobs.city !== undefined
                          ? alljobs.city
                          : ""}
                      </span>
                      <span className="text-disabled-02">
                        <img src={Pen} alt="" width="16px" />
                        {`  `}
                        {alljobs.func !== null && alljobs.func !== undefined
                          ? alljobs.func
                          : ""}
                      </span>
                    </div>

                    {/* <p
                      className="text-disabled-02"
                      dangerouslySetInnerHTML={{
                        __html:
                          alljobs.description !== null &&
                          alljobs.description !== undefined
                            ? alljobs.description
                            : "",
                      }}
                    /> */}
                    <div className="row">
                      <div className="col-lg-9" style={{ color: "#011f95" }}>
                        {" "}
                        View details
                      </div>
                      <div className="col-lg-3">
                        <p className="posted-today-text-rexr-o1">
                          {alljobs.ago !== null && alljobs.ago !== undefined ? (
                            <> {alljobs.ago} ago </>
                          ) : (
                            ""
                          )}
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
              ))
            ) : (
              <p className=" pt-3">Posted jobs will appear here!</p>
            )}
            {/* <div className="pagination-container">
              <div className="pagination">
                <nav aria-label="Page navigation example">
                  <ul className="pagination">
                    <li className="page-item" style={{ border: "none" }}>
                      <a
                        className="page-link"
                        aria-label="Previous"
                        onClick={handlePreviousPage}
                      >
                        <span aria-hidden="true">&laquo;</span>
                      </a>
                    </li>

                    {pageNumbers.map((currentPage) => (
                      <li className="page-item" key={currentPage}>
                        <a
                          className={
                            currentPage == pageNumber
                              ? "page-link selected-page"
                              : "page-link"
                          }
                          onClick={() => handleNavClick(currentPage)}
                        >
                          {currentPage}
                        </a>
                      </li>
                    ))}

                    <li className="page-item">
                      <a
                        className="page-link"
                        aria-label="Next"
                        onClick={handleNextPage}
                      >
                        <span aria-hidden="true">&raquo;</span>
                      </a>
                    </li>
                  </ul>
                </nav>
                <br />
                <Stack spacing={2}>
                  <Pagination
                    count={props?.alljobsReducer?.alljobs?.total_pages}
                    color="primary"
                    onChange={handlePaginationClick}
                    defaultPage={pageNumber}
                  />
                </Stack>
              </div>
            </div> */}
            {/* <Stack spacing={2}>
              <Pagination
                count={props?.alljobsReducer?.alljobs?.total_pages}
                color="primary"
                onChange={handlePaginationClick}
                defaultPage={pageNumber}
                siblingCount={3}
                boundaryCount={1}
                size="large"
              />
            </Stack> */}
            <div className="row mt-3">
              <div className="col-md-9 mt-4">
                <Stack spacing={2}>
                  <Pagination
                    count={props?.alljobsReducer?.alljobs?.total_pages}
                    color="primary"
                    onChange={handlePaginationClick}
                    defaultPage={pageNumber}
                    siblingCount={2}
                    boundaryCount={1}
                    size="large"
                  />
                </Stack>
              </div>
              <div className="col-md-3 mt-3 mb-3">
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Limit Jobs By Number{" "}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={limitNumber}
                      label="Limit Jobs By Number"
                      onChange={handleChange}
                    >
                      <MenuItem value={25}>25</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                      <MenuItem value={75}>75</MenuItem>
                      <MenuItem value={100}>100</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </div>
            </div>
          </div>

          <div className="col-md-3">
            <div className="place-ad">
              <h4>Place Ad Here!</h4>
            </div>
          </div>
        </div>
        {props.alljobsReducer.loading == false ? <FullPageLoader /> : null}
      </div>
      <Footer />
    </>
  );
}

const mapStateToProps = (state) => ({
  alljobsReducer: state.alljobsReducer,
  deletejobReducer: state.deletejobReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getAllJobs: (userId, pageNumber, limit) =>
    dispatch(getAllJobs(userId, pageNumber, limit)),
  getDeleteJob: (userId, id) => dispatch(getDeleteJob(userId, id)),
});
export default connect(mapStateToProps, mapDispatchToProps)(PostedJobs);
