export const getAllJobs = (userId, pageNumber, limit) => {
  console.log(limit);
  return (dispatch) => {
    /// get request
    fetch(
      `${process.env.REACT_APP_API_END_POINT}/web/posted_jobs.php?pagination=1&page=${pageNumber}&items_per_page=${limit}`,
      {
        method: "GET",
        headers: { "Content-Type": "application/json", auth_id: `${userId}` },
      }
    )
      .then((res) => res.json())
      .then((response) => {
        const alljobs = response.data;

        dispatch({
          type: "GET_ALLJOBS",
          alljobs: alljobs,
          alljobsResponse: "got it",
          loading: true,
        });
      })
      .catch((error) => {
        console.log("error", error);
        dispatch({
          type: "GET_ALLJOBS",
          alljobs: {},
          alljobsResponse: null,
          loading: true,
        });
        // alert("Please Check Your Internet Connection...")
      });
  };
};

export const getClosedJobs = (userId) => {
  return (dispatch) => {
    /// get request
    fetch(`${process.env.REACT_APP_API_END_POINT}/web/get_closed_jobs.php`, {
      method: "GET",
      headers: { "Content-Type": "application/json", auth_id: `${userId}` },
    })
      .then((res) => res.json())
      .then((response) => {
        const alljobs = response.data;
        dispatch({
          type: "GET_ALLJOBS",
          alljobs: alljobs,
          alljobsResponse: "got it",
          loading: true,
        });
      })
      .catch((error) => {
        console.log("error", error);
        dispatch({
          type: "GET_ALLJOBS",
          alljobs: {},
          alljobsResponse: null,
          loading: true,
        });
      });
  };
};
