import React, { useEffect, useState } from "react";
import "./CompanyProfile.css";
import ComapnyProfile from "../../Assests/company-profile/company-profile.png";
import EditIcon from "../../Assests/company-profile/edit.svg";
import Nav2 from "../../Components/Nav2/Nav2";
import Footer from "../../Components/Footer/Footer";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getCompanyInfo } from "../../actions/companyinfoAction";
import FullPageLoader from "../../Components/fullpageloader/fullPageLoader";
import place from "../../Assests/placeholderbuilding.svg";
import axios from "axios";
import Modal from "react-modal";

function CompanyProfile(props) {
  const [video, setVideo] = useState();
  const [isUploading, setIsUploading] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleFileChange = (event) => {
    setVideo(event.target.files[0]);
    console.log(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsUploading(true);
    const formData = new FormData();
    formData.append("recruiter_id", localStorage.getItem("recruiter_id"));
    formData.append("video", video);
    console.log(formData.get("recruiter_id"));
    if (formData.get("video")) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_VIDEO_END_POINT}/update-video`,
          formData
        );
        console.log(response);
        setIsUploading(false);
        window.location.reload();
      } catch (error) {
        console.log(error);
        setIsUploading(false);
      }
    }
  };

  const handleDelete = async (event) => {
    event.preventDefault();
    setIsDeleting(true);
    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_API_VIDEO_END_POINT
        }/delete-video/${localStorage.getItem("recruiter_id")}`
      );
      console.log(response);
      setIsDeleting(false);
      window.location.reload();
    } catch (error) {
      console.log(error);
      setIsDeleting(false);
    }
    setDeleteModal(false);
  };

  useEffect(() => {
    jobData(localStorage.getItem("auth_id1"));
  }, []);

  const jobData = async (userId) => {
    await props.getCompanyInfo(userId);
    return null;
  };

  console.log(props?.companyinfoReducer?.companyinfo);
  return (
    <>
      <Nav2 />
      <div className="container mt-5 pt-4 mb-5 pb-5">
        <div
          className="row px-5 py-5 mt-4 shadow"
          style={{ borderRadius: "15px" }}
        >
          <div className="col-12 col-md-2 company-profile-pic ">
            {props.companyinfoReducer.companyinfo &&
            props.companyinfoReducer.companyinfo.dp !== null &&
            props.companyinfoReducer.companyinfo.dp !== undefined &&
            props.companyinfoReducer.companyinfo.dp !== "" ? (
              <img
                src={props.companyinfoReducer.companyinfo.dp || ComapnyProfile}
                style={{
                  objectFit: "contain",
                  width: "100px",
                  height: "100px",
                  borderRadius: "50%",
                }}
              />
            ) : (
              <img
                className="border"
                src={place}
                height="100%"
                width="70%"
                style={{ objectFit: "cover", borderRadius: "50%" }}
              />
            )}
          </div>
          <div className="col-12 col-md-8 pl-lg-4 pl-md-4  pt-2 my-auto">
            <h3 className="company-profile-font-head  mb-0">
              {props.companyinfoReducer.companyinfo &&
              props.companyinfoReducer.companyinfo.name !== null &&
              props.companyinfoReducer.companyinfo.name !== undefined
                ? props.companyinfoReducer.companyinfo.name
                : ""}
            </h3>
            <p className="company-profile-font-para mb-0">
              {props.companyinfoReducer.companyinfo &&
              props.companyinfoReducer.companyinfo.tagline !== null &&
              props.companyinfoReducer.companyinfo.tagline !== undefined
                ? props.companyinfoReducer.companyinfo.tagline
                : ""}
            </p>
            <p className="mt-2" style={{ color: "lightgray" }}>
              {props.companyinfoReducer.companyinfo &&
              props.companyinfoReducer.companyinfo.country !== null &&
              props.companyinfoReducer.companyinfo.country !== undefined
                ? props.companyinfoReducer.companyinfo.country
                : ""}
              {","}
              {props.companyinfoReducer.companyinfo &&
              props.companyinfoReducer.companyinfo.state !== null &&
              props.companyinfoReducer.companyinfo.state !== undefined
                ? props.companyinfoReducer.companyinfo.state
                : ""}
              {","}
              {props.companyinfoReducer.companyinfo &&
              props.companyinfoReducer.companyinfo.city !== null &&
              props.companyinfoReducer.companyinfo.city !== undefined
                ? props.companyinfoReducer.companyinfo.city
                : ""}{" "}
              |{" "}
              {props.companyinfoReducer.companyinfo &&
              props.companyinfoReducer.companyinfo.industry !== null &&
              props.companyinfoReducer.companyinfo.industry !== undefined
                ? props.companyinfoReducer.companyinfo.industry
                : ""}
            </p>
          </div>
          <div className="col-12 col-md-2 d-flex justify-content-end">
            <Link to="/EditCompanyProfile">
              <button className="btn-comp-prof-rcer">
                EDIT <img src={EditIcon} />
              </button>
            </Link>
          </div>
        </div>
        <div className="row mt-4 pb-5">
          <div className="col-md-5">
            <div className="row shadow" style={{ borderRadius: "15px" }}>
              <div className="col-md-12 pt-3 pb-2">
                <h2 className="heading-main-comp-sub-child">About Us</h2>
                <p className="company-profile-about-para px-2">
                  {props.companyinfoReducer.companyinfo &&
                  props.companyinfoReducer.companyinfo.desc !== null &&
                  props.companyinfoReducer.companyinfo.desc !== undefined
                    ? props.companyinfoReducer.companyinfo.desc
                    : ""}
                </p>
              </div>
            </div>
            <div className="row shadow mt-4" style={{ borderRadius: "15px" }}>
              <div className="col-md-12 pt-3 pb-2">
                {props?.companyinfoReducer?.companyinfo?.video_url ? (
                  <>
                    {" "}
                    <video
                      src={props?.companyinfoReducer?.companyinfo?.video_url}
                      controls
                      style={{ width: "100%" }}
                    ></video>
                    <div
                      className="delete-button"
                      onClick={() => setDeleteModal(true)}
                    >
                      Delete Video
                    </div>
                  </>
                ) : (
                  <form>
                    <div className="video-container">
                      <div style={{ textAlign: "center" }}>
                        <label
                          for="fileInput"
                          style={{
                            backgroundColor: "#007bff",
                            cursor: "pointer",
                          }}
                        >
                          {video ? video.name : "Select a video file to upload"}
                        </label>

                        <input
                          type="file"
                          name="file"
                          accept="video/*"
                          onChange={handleFileChange}
                          id="fileInput"
                        />
                      </div>
                      <div>
                        <button
                          onClick={handleSubmit}
                          disabled={isUploading}
                          className="btn btn-primary  btn-of-edit-comp-prof"
                          style={{ width: "100%" }}
                        >
                          {isUploading ? "Uploading..." : "Upload"}
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              </div>
            </div>{" "}
          </div>
          <div className="col-md-1"></div>
          <div className="col-md-6">
            <div className="row  py-2 shadow" style={{ borderRadius: "15px" }}>
              <div className="col-md-12 px-4 pt-3 pb-2">
                <div className="row">
                  <div className="col-md-12">
                    <h5 className="main-tit-head mb-0 pb-1">Website</h5>
                    <small style={{ color: "gray" }} className="">
                      {props.companyinfoReducer.companyinfo &&
                      props.companyinfoReducer.companyinfo.website !== null &&
                      props.companyinfoReducer.companyinfo.website !== undefined
                        ? props.companyinfoReducer.companyinfo.website
                        : ""}
                    </small>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 pt-4">
                    <h5 className="main-tit-head mb-0 pb-1">Industry</h5>
                    <small style={{ color: "gray" }} className="">
                      {props.companyinfoReducer.companyinfo &&
                      props.companyinfoReducer.companyinfo.industry !== null &&
                      props.companyinfoReducer.companyinfo.industry !==
                        undefined
                        ? props.companyinfoReducer.companyinfo.industry
                        : ""}
                    </small>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 pt-4">
                    <h5 className="main-tit-head mb-0 pb-1">Company Size</h5>
                    <small style={{ color: "gray" }} className="">
                      {props.companyinfoReducer.companyinfo &&
                      props.companyinfoReducer.companyinfo.size !== null &&
                      props.companyinfoReducer.companyinfo.size !== undefined &&
                      props.companyinfoReducer.companyinfo.size !== ""
                        ? `${props.companyinfoReducer.companyinfo.size} employees`
                        : ""}
                    </small>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 pt-4">
                    <h5 className="main-tit-head mb-0 pb-1">Location</h5>
                    <small style={{ color: "gray" }} className="">
                      {props.companyinfoReducer.companyinfo &&
                      props.companyinfoReducer.companyinfo.country !== null &&
                      props.companyinfoReducer.companyinfo.country !== undefined
                        ? props.companyinfoReducer.companyinfo.country
                        : ""}
                      {", "}
                      {props.companyinfoReducer.companyinfo &&
                      props.companyinfoReducer.companyinfo.state !== null &&
                      props.companyinfoReducer.companyinfo.state !== undefined
                        ? props.companyinfoReducer.companyinfo.state
                        : ""}
                      {", "}
                      {props.companyinfoReducer.companyinfo &&
                      props.companyinfoReducer.companyinfo.city !== null &&
                      props.companyinfoReducer.companyinfo.city !== undefined
                        ? props.companyinfoReducer.companyinfo.city
                        : ""}{" "}
                    </small>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 pt-4">
                    <h5 className="main-tit-head mb-0 pb-1">Founded</h5>
                    <small style={{ color: "gray" }} className="">
                      {props.companyinfoReducer.companyinfo &&
                      props.companyinfoReducer.companyinfo.founded !== null &&
                      props.companyinfoReducer.companyinfo.founded !== undefined
                        ? props.companyinfoReducer.companyinfo.founded
                        : ""}
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {props.companyinfoReducer.loading == false ? <FullPageLoader /> : null}
      </div>
      <Footer />
      <Modal
        isOpen={deleteModal}
        onRequestClose={() => setDeleteModal(false)}
        className="shadow"
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          },
          content: {
            background: "#fff",
            border: "0",
            position: "absolute",
            zIndex: 1000,
            margin: "0 auto",
            width: "525px",
            minHeight: "230px",
            height: "230px",
            top: "400px",
            left: "0",
            right: "0",
            bottom: "50px",
            WebkitOverflowScrolling: "touch",
            outline: "none",
            padding: "30px 0px",
            borderRadius: "20px",
          },
        }}
      >
        <div className="container">
          <div className="row">
            <div
              className="col-12 text-center"
              style={{ fontSize: "30px", fontWeight: "bold" }}
            >
              Are you sure that you want to delete your video?
            </div>
            <div className="col-6 ">
              <button
                className="btn btn-primary w-100 btn-of-edit-comp-prof"
                onClick={() => setDeleteModal(false)}
              >
                Cancel
              </button>
            </div>
            <div className="col-6">
              <button
                className="btn btn-primary w-100 btn-of-edit-comp-prof"
                onClick={handleDelete}
                disabled={isDeleting}
              >
                {isDeleting ? "Deleting..." : "Delete"}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => ({
  companyinfoReducer: state.companyinfoReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getCompanyInfo: (userId) => dispatch(getCompanyInfo(userId)),
});
export default connect(mapStateToProps, mapDispatchToProps)(CompanyProfile);
